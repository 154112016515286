<template>
  <validation-observer ref="observer" rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @submit="handleSubmit(onSubmit)"
      @close="isOpen = false, $emit('close')"
      submit="Submit"
      :loading="isLoading"
      button-class="bg-dynamicBackBtn text-white"
      width="520px"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center">
          <BackButton @onClick="isOpen = false, $emit('close')" variant="secondary" />
          <span class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
            Award Lump Sum
          </span>
        </div>
      </template>
      <template>
        <div class="border-b border-dashed border-romanSilver mb-5">
          <div class="flex justify-between items-start w-full gap-2">
            <div class="w-4/12 h-60 rounded-md text-blueCrayola font-normal">
                <img class="h-56 rounded-md" v-if="employeeInfo.photo" :src="employeeInfo.photo" />
                <div v-else class="h-56 border rounded-md flex justify-center items-center">
                <span style="font-size:60px">
                  {{ $getInitials(`${employeeInfo.fname} ${employeeInfo.lname}`) }}
                </span>
                </div>
              </div>
            <div class="w-8/12 flex flex-col justify-start items-start gap-2">
              <h4 class="font-bold text-lg leading-5 text-darkPurple">
                {{ employeeInfo.fname }} {{ employeeInfo.lname }}
              </h4>
              <div class="flex flex-col justify-start items-center w-full gap-2">
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Function:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    {{ employeeInfo.function }}
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Designation:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    {{ employeeInfo.designation }}
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Job Level:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.level">
                      {{ employeeInfo.level.name }}
                    </span>
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Office Location:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    {{ employeeInfo.location }}
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Manager:
                  </p>
                  <template v-if="(employeeInfo.lineManager)">
                    <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                      {{ employeeInfo.lineManager.fname }} {{ employeeInfo.lineManager.lname }}
                    </p>
                  </template>
                  <p v-else>-</p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Length of Service:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.yearsOfService">
                      {{ employeeInfo.yearsOfService }}
                    </span>
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Last Performance Score:
                  </p>
                  <template v-if="employeeInfo.performanceScore">
                    <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                      {{ employeeInfo.performanceScore }}
                      <span class="text-red-400" v-if="employeeInfo.performanceScore <= 2">(Poor)</span>
                      <span class="text-yellow-400" v-if="(employeeInfo.performanceScore > 2 && employeeInfo.performanceScore < 4)">
                        (Average)
                      </span>
                      <span class="text-yellow-400" v-if="(employeeInfo.performanceScore > 4)">(Excellent)</span>
                    </p>
                  </template>
                  <p v-else>-</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template>
        <div class="container flex justify-between items-center">
          <p class="font-normal text-sm leading-5 text-jet w-1/2">
            Merit-Based Rate (Percentage of Annual Gross)
          </p>
          <TextInput
            type="number"
            placeholder="10%"
            class="w-1/2"
            v-model="payload.meritBasedRate"
            :rules="['required']"
          />
        </div>
      </template>
      <template>
        <div class="flex flex-col justify-start items-start gap-5 my-5">
          <div class="flex justify-between items-center w-full">
            <p class="font-semibold text-sm leading-5 text-romanSilver">
              Salary Range:
            </p>
           <p class="font-semibold text-sm leading-5 text-jet">
              <span v-if="employeeInfo.paygrade">
                {{ convertToCurrency(employeeInfo.paygrade.rangeMinimum) }}
                -
                {{ convertToCurrency(employeeInfo.paygrade.rangeMaximum) }}
              </span>
              <span v-else> - </span>
            </p>
          </div>
          <div class="flex justify-between items-center w-full">
            <p class="font-semibold text-sm leading-5 text-romanSilver">
              Current Annual Gross
            </p>
            <p class="font-semibold text-sm leading-5 text-jet">
              <span v-if="employeeInfo.employee">
                {{ convertToCurrency(employeeInfo.employee.grossPay) }}
              </span>
              <span v-else> - </span>
            </p>
          </div>
          <div class="flex justify-between items-center w-full">
            <p class="font-semibold text-sm leading-5 text-romanSilver">
              Lump Sum Award:
            </p>
            <p class="font-semibold text-sm leading-5 text-jet">
              <span v-if="employeeInfo.employee">
                {{ computedAnnualGross(employeeInfo.employee.grossPay) }}
              </span>
            </p>
          </div>
        </div>
      </template>
      <div class="flex flex-col justify-start items-start gap-5">
        <template>
          <div class="w-8/12">
            <span class="font-semibold text-base leading-5 text-darkPurple">
              Effective
            </span>
            <RadioButton
              v-model="isEffectiveDate"
              space-between="mr-2 ml-2 text-jet text-base font-normal leading-5"
              :options="[
                { name: 'Immediately', value: 'immediately' },
                { name: 'Set Date', value: 'setDate'},
              ]"
            />
          </div>
          <div v-if="isEffectiveDate === 'setDate'" class="w-full">
            <div class="w-full flex flex-col justify-start gap-2">
              <p class="text-jet text-sm mr-5">Start Date</p>
              <validation-provider class="w-full" name="Effective date" :rules="{required: true}" v-slot="{ errors }">
                <div :class="errors[0] ? 'border border-desire rounded-md' : 'date-flex'">
                  <datepicker
                    placeholder="--Start Date--"
                    input-class="date-input"
                    style="width:100%;"
                    :rules="['required']"
                    v-model="payload.effectiveDate"
                  />
                </div>
                <small class="text-desire">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
        </template>
        <template>
          <validation-provider class="w-full" name="Justification" :rules="{required: true}" v-slot="{ errors }">
            <div class="flex flex-col justify-start items-start w-full gap-1">
              <span class="font-normal text-sm text-darkPurple">Justification</span>
              <div class="w-full flex flex-col justify-start gap-2">
                <Textarea
                  class="w-full"
                  label="Justification"
                  placeholder="Text Area"
                  :height="144" :maxlength="3"
                  v-model="payload.justification"
                />
                <div class="w-full flex flex-row justify-between text-sm">
                  <p v-if="payload.justification.length > 300" class="text-flame w-full whitespace-nowrap">
                    * Justification should not exceed max count of 300
                  </p>
                  <p class="font-normal text-xs leading-5 text-jet flex justify-end w-full">
                    {{ payload.justification.length }}/300
                  </p>
                </div>
              </div>
            </div>
            <span class="text-flame">{{ errors[0] }}</span>
          </validation-provider>
        </template>
      </div>
    </RightSideBar>
  </validation-observer>
</template>

<script>
  import { format } from "date-fns";
  import Datepicker from "vuejs-datepicker";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
  import TextInput from "@scelloo/cloudenly-ui/src/components/text";
  import Textarea from "@scelloo/cloudenly-ui/src/components/text-area";
  import RadioButton from "@scelloo/cloudenly-ui/src/components/radio";
  import RightSideBar from "@/components/RightSideBar";

  export default {
    name: 'AwardLumpSum',
    components: {
      ValidationObserver,
      ValidationProvider,
      Datepicker,
      BackButton,
      RadioButton,
      TextInput,
      Textarea,
      RightSideBar,
    },
    data(){
      return {
        isOpen: false,
        isLoading: false,
        isEffectiveDate: 'immediately',
        employeeInfo: {},
        payload: {
          meritBasedRate: '',
          justification: '',
          effectiveDate: new Date(),
        }
      }
    },
    methods: {
      computedAnnualGross(value){
        return this.convertToCurrency(
          Math.fround(((this.payload.meritBasedRate / 100) * value))
        )
      },
      toggle({ userId, paygradeId }){
        this.getEmployeeCompensationSummary({ userId, paygradeId })
        this.isOpen = true
      },
      getEmployeeCompensationSummary(payload){
        this.isLoading = true
        this.$_getEmployeeCompensationSummary(payload).then(({ data }) => {
          this.employeeInfo = {
            ...data.employee,
            ...data.employee.paygrade,
            ...data.employee.userDetails,
          }
          this.isLoading = false
        }).catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 })
          this.isLoading = false
        })
      },
      onSubmit(){
        this.isLoading = true

        const payload = {
          ...this.payload,
          orgId: this.$orgId,
          userId: this.$AuthUser.id,
          effectiveDate: format(this.payload.effectiveDate, "yyyy-MM-dd")
        }

        this.$_compensationAwardLumpSum(payload).then(({ data }) => {
          this.$toasted.success(data.message, {duration: 3000})
          this.$emit('success', data.success)
          this.isLoading = false
          this.isOpen = false
        }).catch((error) => {
          this.$toasted.error(error.message, {duration: 3000})
          this.isLoading = false
        })
      },
    },
  }
</script>

<style>
  .rightSidebar{
    width: 540px !important;
  }
</style>

